<!--  -->
<template>
  <div class="container">
    <div v-if="type=='1'">
        <img src="../../static/ic_success.png" alt="">
        <p>退票成功</p>
    </div>
    <div v-else>
        <img src="../../static/fail.png" alt="">
        <p>退票失败</p>
    </div>
  </div>
</template>

<script>

export default {
    data () {
        return {
           type:'1'
        };
    },
    mounted(){
        this.type = this.$route.query.type
    },
    methods: {
      
    },

    
}

</script>
<style scoped>
.container img{
    width: 1.92rem;
    height: 1.92rem;
    float: left;
    display: block;
    margin: 5.04rem 0 0 4.05rem;
}
.container p{
    width: 10rem;
    float: left;
    margin-top: 0.53rem;
    text-align: center;
    font-size: 0.373rem;
    color:#222222;
}

</style>